<style scoped>
::v-deep .v-dialog {position: absolute;top:5%}
</style>
<template>
  <v-dialog class="ordersCancelDialog" v-model="showDialog" max-width="700px" persistent origin="top top"><v-expand-transition>
        <v-card class="pa-5"><div class="pb-1 font-weight-bold">Cancel Order</div><div class="">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left"><span>Instrument</span></th>
                  <th class="text-right"><span>Qty</span></th>
                  <th class="text-center"><span>Price</span></th>
                  <th class="text-left"><span>Type</span></th>
                  <th class="text-left"><span>Product</span></th>
                  <th class="text-left"><span>Validity</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.selectedOrders" :key="index">
                  <td class="text-left">
                    <span class="tradingSymbol">{{ item.Trsym }}</span>
                    <span class="fsize10"> &nbsp;{{ item.Exchange }}</span>
                  </td>

                  <td class="text-right" v-if="parseFloat(item.Qty) > 0">
                    <span>{{ item.Qty }}</span>
                  </td>

                  <!-- <td class="text-right" v-if="parseFloat(item.Qty) < 0">
                    <span>{{ item.Netqty.slice(1) }} </span>
                  </td> -->

                  <td class="text-center">
                    <span>{{ item.Prc }}</span>
                  </td>

                  <td class="text-left">
                    <span>{{ item.Trantype == 'B' ? 'BUY' : 'SELL' }}</span>
                  </td>

                  <td class="text-left">
                    <span>{{ item.prd == "M" ? "MIS" : "NRML" }}</span>
                  </td>

                  <td class="text-left">
                    <span>DAY</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="text-right pt-4"> <v-btn height="32" min-width="80" depressed class="mr-2 fsize12 text-capitalize" color="#E6e6e6" @click="closeDialog()">
              Close
            </v-btn>
            <v-btn height="32" min-width="80" depressed class="fsize12 text-capitalize" color="primary" @click="cancelOrder()">Confirm</v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>
</template>

<script>
import { mapState} from "vuex"
export default {
  data: () => ({
  }),
  computed: {
     ...mapState('dialog',['showDialog','selectedOrders']),
  },
  methods: {
     closeDialog() {
         this.$store.commit('dialog/setDialog',false);
     },
     async calcelOrder(type) {
       await this.$store.dispatch('dialog/cancelOrder',type);
     },
     async cancelOrder() {
       for(let item of this.selectedOrders)
         await this.$store.dispatch('dialog/cancelOrder',item);
      },
  }
}
</script>

<style>

</style>