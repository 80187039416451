<style scoped>
 .sellcolor{ background-color: #f7edef;color: #f03; padding: 4px 16px; }
 .buycolor{ background-color: #e8f4ff;color: #0060b9; padding: 4px 17px;}
 .completed-msg{ background-color: #e5f7f0;color: #087751;padding: 4px 24px;}
 .rejected-msg{ background-color: #ffebf0;color: #dc143d; padding: 4px 26px; }
 .cancelled-msg{ background-color: #e9ecf1;color: #333638; padding: 4px 22px; }
 .ordersTable{border-bottom: 1px solid #E1E1E1;}
 /* darktheme colors */
 .theme--dark .buycolor{ background-color:#1C232D;color: #4987ee;}
 .theme--dark .sellcolor{ background-color: #28191A;color: #df514c;}
 .theme--dark .cancelled-msg{ background-color: #222222;color: #bbb;}
 .theme--dark .completed-msg{ background-color: #222222;color: #bbb;}
 .theme--dark .rejected-msg{ background-color: #28191A;color: #df514c;}
 .theme--dark  .ordersTable{border-bottom: 1px solid #000000;}

 .alignfields{ font-size: 10px; border-radius: 2px; text-align: center; min-width: 120px; }
 .fontsize10{ font-size: 10px; }
 .w-255{width:255px}
 ::v-deep .v-dialog{ position: absolute;top:5%}

/* Links inside the dropdown */
.dropdown-content a {
  color: #000000 !important;
  padding: 0.5rem 1rem !important;
  text-decoration: none;
  display: block;
  background-color: #ffffff;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
}
.border-a2a2a2 {
  border: solid 1px #a2a2a2 !important;
  color: #0075e1 !important;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}
/* .show {
  display: block;
}
.hide {
  display: none;
} */
.hoverParent:hover .moreBtn {display: block !important;}
.moreBtn { display: none;}
.hoverBtnsStyle {color: #fff;height: 25px;width: 35px;font-size: 11px;border-radius: 3px;}
.position-absolute{position: absolute}
.position-relative{position: relative}
.fsize10{font-size: 10px !important;}
.right-0{right:0}
.dropdown {
  /* position: relative; */
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed !important;
  background-color: #f9f9f9;
  min-width: 130px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
  z-index: 1;
  border-radius: 8px !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>

<template>
  <div>
    <div class="pa-2 w-255">
    <v-tabs v-model="tab" height="36" class="horizontal-tab tabsegment c-b-white" tag="button">
       <v-tab height="28" color="#ffffff" @click="getOrders('openOrders')">Open</v-tab>
       <v-tab height="28" color="#ffffff" @click="getOrders('executedOrders')">Executed</v-tab>
     </v-tabs>
     </div>
      <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0 pt-0">
              <v-card v-if="currentOrders != 0">
     <v-simple-table class="ordersTable" flat>
     <thead>
        <tr>
          <th v-if="currentTab == 'openOrders'" width="20" class="text-center"><v-checkbox v-model="checkBoxSelectAll" :ripple="false"></v-checkbox></th>
          <th class="text-center pa-3">Time</th>
          <th class="text-center">Type</th>
          <th class="text-left">Instrument</th>
          <th class="text-left">Product</th>
          <th class="text-right">Qty.</th>
          <th class="text-right">Price</th>
          <th class="text-center">Status</th>
        </tr>
      </thead>
        <tbody>
          <tr class="ordersTable hoverParent" v-for="(item, index) in currentOrders" :key="index">
            <td v-if="currentTab == 'openOrders'" width="20"><v-checkbox v-model="selectedCheckbox" :value="item" :ripple="false"></v-checkbox></td>
            <td class="text-center pa-4 subtitle-2">{{ item.OrderedTime.toString().split(" ")[1] }}</td>
            <td class="text-center"><span class="alignfields text-center" :class="item.Trantype == 'S' ?
             'sellcolor' :  item.Trantype == 'B' ? 'buycolor' : ''">{{ item.Trantype == "B" ? "BUY" : "SELL" }}</span></td>
            <td class="text-left subtitle-2 position-relative">{{ item.Trsym }} <span class="ml-1 fontsize10">{{ item.Exchange }}</span>
            <!-- dropdown -->
             <div class="dropdown position-absolute right-0">
               <v-btn depressed max-width="50" height="26" outlined class="primary moreBtn white--text text-capitalize hoverBtnsStyle">More</v-btn>
              <div class="dropdown-content">
                <a v-if="currentTab == 'openOrders'" class="fsize12" @click="callOrderwindow(item,'BUY')">Modify</a>
                <a v-if="currentTab != 'openOrders'" class="fsize12" @click="callOrderwindow(item,'BUY')">Buy</a>
                <a v-if="currentTab != 'openOrders'" class="fsize12" @click="callOrderwindow(item,'SELL')">Sell</a>
                <a class="fsize12" @click="cancelOrder(item,'single')" v-if="currentTab == 'openOrders'">Cancel</a>
              </div>
            </div>

            </td>
            <td class="text-left subtitle-2">{{ item.Pcode }}</td>
            <td class="text-right subtitle-2">{{ item["Qty"]}}</td>
            <td class="text-right subtitle-2">{{ item.Prc }}</td>
            <td class="text-center uppercase" v-if="item.Status == 'rejected'">
              <v-tooltip top max-width="250px">
              <template v-slot:activator="{ on, attrs }">

              <button v-bind="attrs" v-on="on" class="alignfields" :class="item.Status == 'complete' ? 'completed-msg': 
            item.Status == 'rejected' ? 'rejected-msg' : item.Status == 'cancelled' || item.Status == 'cancelled after market order' ||
             item.Status == 'open' || item.Status == 'after market order req received' ? 'cancelled-msg' : 'cancelled-msg'">
             {{ item.Status == 'cancelled after market order' ? 'CANCELLED AMO' : item.Status == 'modified after market order req received' ? 
             'MODIFIED AMO' : item.Status == 'after market order req received' ? 'AMO' : item.Status.toUpperCase() }}</button>
             </template><span class="text-uppercase fsize12">{{ item.RejReason == "--" ? '' : item.RejReason }}</span></v-tooltip></td>

             <td  class="text-center uppercase" v-else>
                <button class="alignfields" :class="item.Status == 'complete' ? 'completed-msg': 
            item.Status == 'rejected' ? 'rejected-msg' : item.Status == 'cancelled' || item.Status == 'cancelled after market order' ||
             item.Status == 'open' || item.Status == 'after market order req received' ? 'cancelled-msg' : ''">
             {{ item.Status == 'cancelled after market order' ? 'CANCELLED AMO' : item.Status == 'modified after market order req received' ? 
             'MODIFIED AMO' : item.Status == 'after market order req received' ? 'AMO' : item.Status.toUpperCase() }}</button>
             </td>
          </tr>
        </tbody>
        <!-- <tfoot v-if="selectedCheckbox.length > 0">
          <td colspan="7" class="pa-4 subtitle-2"></td>
        </tfoot> -->
      </v-simple-table>
      <div class="pa-4" v-if="selectedCheckbox.length > 0 && currentTab == 'openOrders'">
          <span v-if="currentTab == 'openOrders'"><v-btn @click="dialogCall(true)" depressed height="32" class="text-caption text-capitalize primary rounded-md">Cancel All</v-btn></span>
        </div>
        </v-card>
          <div>
            <div class="d-flex justify-center align-center no-data" v-if="currentOrders == 0">NO DATA FOUND</div>
          </div>
          </v-flex>
        </v-layout>
      </v-container>
  </v-flex> 

  <v-dialog v-model="cancelOrderDialog" max-width="500px" persistent origin="top">
    <v-card class="pa-4">
      <p class="subtitle-1 font-weight-bold">Cancel Order</p>
       <p class="subtitle-2">{{currentCancelOrder.Trsym}}</p>
       <p class="subtitle-2">#{{currentCancelOrder.Nstordno}}</p>
       <div class="text-right">
         <v-btn @click='cancelOrderDialog=false' depressed min-width="100" height="32" class="mr-2 text-capitalize">Close</v-btn><v-btn @click="callCancelOrder(currentCancelOrder)" class="primary text-capitalize" depressed min-width="100" height="32">Confirm</v-btn>
       </div>
    </v-card>
  </v-dialog>
  
  <dialogWindow />
  </div>
</template>

<script>
import {mapState} from "vuex"
import dialogWindow from "@/views/selectedorders-dialog.vue";
export default {

  components:{
      dialogWindow
  },

    data: () => ({
      tab: 0,
      showdata: false,
      currentTab:'openOrders',
      selectedCheckbox: [],
      dropdownContent:false,
      currentCancelOrder:[],
      cancelOrderDialog:false
    }),

    computed:{
        ...mapState('orders',['orderBookData','currentOrders',]),
        checkBoxSelectAll: {
           get: function () {
             console.log(this.selectedCheckbox,'console.log(this.selectedCheckbox)')
             return this.currentOrders
               ? this.selectedCheckbox.length == this.currentOrders.length
               : false;
           },
           set: function (value) {
             var selected = [];
             if (value) {
               this.currentOrders.forEach(function (item) {
                 selected.push(item);
               });
             }
             this.selectedCheckbox = selected;
           },
        },
        },
    watch: {
      $route(to, from) {
      // react to route changes...
      this.currentTab = to.query.tab
      console.log(this.currentTab , 'currentTab')
      if(this.currentTab == 'executedOrders'){
        console.log(this.orderBookData , 'executedOrders')
      }
      },
    },
    methods: {
      dialogCall(val) {
         this.$store.commit('dialog/setDialog',val);
         this.$store.commit('dialog/setSelectedOrders',this.selectedCheckbox);
      },
      call(val) {
        val.showdropdown = !val.showdropdown
        val.showUllist = val.showdropdown
        console.log(val.showdropdown ,'val.showdropdown')
        console.log(val.showUllist ,'val.showdropdown')
        console.log(this.orderBookData , 'executedOrders')
      },
      async cancelOrder(val) {
        this.currentCancelOrder = val;
        this.cancelOrderDialog = true;
      },
      async callCancelOrder(value) {
        await this.$store.dispatch('dialog/cancelOrder',value);
        this.cancelOrderDialog = await false;
        this.selectedCheckbox = await []
      },
      async callOrderwindow(item,orderType){
        this.$store.commit('orderWindow/setCurrentOrder',await item)
        this.$store.commit('orderWindow/setOrderType',await orderType);
        this.$store.commit('orderWindow/setToggleOrder',await orderType == 'BUY' ? true : false);
        this.$store.commit('orderWindow/setOrderWindow',await true);
      },
      async getOrders(selectedTab) { 
         await this.$store.dispatch('orders/fetchOrderBook' ,selectedTab)
         if(selectedTab == 'openOrders') {
            this.$store.dispatch('orders/getOpenOrders');
            this.tab = 0;
         }else if(selectedTab == 'executedOrders') {
            this.$store.dispatch('orders/getExecutedOrders');
            this.tab = 1;
         }
         this.$router.push({ path: this.$router.history.current.fullPath, query: { tab: selectedTab } }).catch(() => {});
      }
    },
    created() {
      var currentPath = this.$route.query.tab
      this.currentTab = currentPath
      currentPath ? this.getOrders(currentPath) : this.getOrders('openOrders')
    }
 }
</script>

